import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import style from './Form.module.scss';
// import Button, { VARIANT } from '@components/Button/Button.js';
// import Input from '../Input/Input';
// import TextArea from '../TextArea/TextArea';
// import Counter from '../Counter/Counter';
// import { ScheduleCall } from '../ScheduleCall';
import { isValidEmail } from '@helpers';
// import ReCAPTCHA from 'react-google-recaptcha';

const initialState = {
  company: '',
  email: '',
  question: '',
  question2: '',
  counter: 0,
  schedule_call: 'Yes',
};

const errors = { email: 'email', company: 'company' };
// const SITE_KEY = '6LfX-3MpAAAAACcZI4PHqIdB5glRjQEwbYITR3Fg';
// SP captchaSite key
// const SITE_KEY = '6LeqoHIpAAAAACgV6YYyH5257cjFH9uYLlmhUbvq';
// const SITE_KEY = "6Lf_pXMpAAAAAFqlPQ2rEJSnK8kIdlD2C_8_61pq";

const CONTACT_FORM_URL = process.env.GATSBY_CONTACT_FORM_URL;
const MIN_COUNTER_VALUE = 0;
const MAX_COUNTER_VALUE = 100;

const getUserEmailProvider = (mail) => {
  const splitted = mail.split('@');
  const provider = splitted[1];

  return provider;
};

const Form = ({ content }) => {
  const {
    body,
    successinformer,
    button,
    company,
    counter,
    email,
    question,
    question2,
    wrong_email,
    required_field,
    schedule_call,
  } = content;
  const { text: successInformerText } = successinformer;
  const [formState, setFormState] = useState(initialState);
  const [formErrors, setFormErrors] = useState([]);
  // const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(null);
  const [recaptchaLoad, setRecaptchaLoad] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isSubmitted, setSubmited] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const reCaptchRef = useRef(null);
  const providersData = body.find(
    (element) => element.slice_type === 'providers'
  );
  const { items: rawProviders } = providersData;
  const providers = rawProviders.map(({ provider }) => provider.text);

  const handleInputChange = ({ target: { name, value } }) => {
    setFormState((state) => ({ ...state, [name]: value }));
    formErrors.length > 0 &&
      setFormErrors([...formErrors.filter((error) => error != name)]);
  };

  const handleChangeCounter = (value) => {
    if (value >= MIN_COUNTER_VALUE && value <= MAX_COUNTER_VALUE) {
      setFormState((state) => ({ ...state, ['counter']: value }));
    }
  };

  const handleCloseInformer = (informerType) => {
    const mapping = {
      successInformer: setSubmited(false),
      errorInformer: setSubmitError(null),
    };

    return mapping[informerType];
  };

  const validateForm = (companyName, userEmail) => {
    const userEmailProvider = getUserEmailProvider(userEmail);

    const isValidCompany = companyName.trim() !== '';
    const isValidUserEmail =
      isValidEmail(userEmail) && !providers.includes(userEmailProvider);

    if (!isValidCompany) {
      setFormErrors([...formErrors, errors.company]);
    }

    if (!isValidUserEmail) {
      setFormErrors([...formErrors, errors.email]);
    }

    const validForm = isValidCompany && isValidUserEmail;

    return validForm;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    // const { company, email } = formState;
    // const isValidForm = validateForm(company, email);

    // if (!recaptchaToken) {
    //   setRecaptchaError('Please complete the reCAPTCHA');
    //   return;
    // // }
    // if (reCaptchRef?.current && reCaptchRef?.current?.getValue()) {
    //   isValidForm &&
    //     fetch(CONTACT_FORM_URL, {
    //       method: 'POST',
    //       headers: {
    //         "Accept": "application/json"
    //       },
    //       body: new FormData(e.target),
    //     })
    //       .then(() => {
    //         if (submitError) {
    //           setSubmitError(null);
    //         }
    //         setSubmited(true);
    //         setFormState(initialState);
    //       })
    //       .catch((err) => {
    //         if (isSubmitted) {
    //           setSubmited(null);
    //         }
    //         setSubmitError(err);
    //       });
    // }
    // if (isValidForm) {
    //   // if (submissionCount === 0) {
    //     fetch(CONTACT_FORM_URL, {
    //       method: 'POST',
    //       headers: {
    //         "Accept": "application/json"
    //       },
    //       body: new FormData(e.target),
    //     })
    //       .then(() => {
    //         // setSubmissionCount(submissionCount + 1);
    //         setSubmited(true);
    //         setFormState(initialState);
    //       })
    //       .catch((err) => {
    //         setSubmitError(err);
    //       });
    // } 
    // else
    // if (isValidForm) {
    // if (reCaptchRef.current && reCaptchRef.current.getValue()) {
    fetch(CONTACT_FORM_URL, {
      method: 'POST',
      headers: {
        "Accept": "application/json"
      },
      body: new FormData(e.target),
    })
      .then(() => {
        // setSubmissionCount(submissionCount + 1);
        setSubmited(true);
        setFormState(initialState);
      })
      .catch((err) => {
        setSubmitError(err);
      });
    // }
    // else {
    //   setSubmitError(new Error('Please complete the reCAPTCHA'));
    // }
    // }
    // }
    // console.log(isValidForm, "isValidForm")
  };

  // console.log(formState, "formState")

  const handleCaptureVerify = (token) => {
    console.log(token, '--capturedVerify');
    console.log('----Captcha');
    setRecaptchaToken(token);
    // setRecaptchaError(null);
  };

  // const hanleRecaptchaLoad = () => {
  //   setRecaptchaLoad(true);
  // };

  // useEffect(() => {
  //   hanleRecaptchaLoad();
  // }, []);


  // useEffect(() => {
  //   setTimeout(() => {
  //     setSubmissionCount(submissionCount + 1);

  //   }, 1500);

  // }, []);

  // console.log(formState, 'formState');
  // console.log(reCaptchRef, 'reCaptchRef');
  // console.log(recaptchaToken, '-->recaptchaToken');

  return (
    <form className={style.form} onSubmit={handleOnSubmit}>
      <>
        <iframe id="JotFormIFrame-241301827720347" title="Contact Us form"
          allow="geolocation; microphone; camera; fullscreen" src="https://form.jotform.com/241301827720347"
          frameborder="0" style="width: inherit;
              height: 1150px;" />
        {/* <div className={style.container}>
        <div className={style.row}>
          <Input
            id="company"
            placeholder={company.text}
            errorMessage={required_field.text}
            name="company"
            valid={!formErrors.includes('company')}
            value={formState.company}
            handleChange={handleInputChange}
          />
          <Input
            id="email"
            placeholder={email.text}
            errorMessage={wrong_email.text}
            name="email"
            valid={!formErrors.includes('email')}
            value={formState.email}
            handleChange={handleInputChange}
          />
        </div>
        <div className={style.row}>
          <TextArea
            id="question"
            placeholder={question.text}
            name="question"
            value={formState.question}
            handleChange={handleInputChange}
          />
        </div>
        <div className={style.row}>
          <Counter
            label={counter.text}
            value={formState.counter}
            handleChange={handleChangeCounter}
          />
        </div>
        <div className={style.row}>
          <TextArea
            id="question2"
            placeholder={question2.text}
            name="question2"
            value={formState.question2}
            handleChange={handleInputChange}
          />
        </div>
        <div className={style.row}>
        </div>
        <div className={style.row}>
          <ScheduleCall
            label={'Are you ready to schedule a call?'}
            value={formState.schedule_call}
            handleChange={handleInputChange}
          />
        </div>
        <div className={style.row}>
          <input
            type="hidden"
            name="_gotcha"
            style={{ display: 'none !important' }}
          />
        </div>
        <div className={style.buttonRow}>
          <div className={style.buttonWrapper}>
            <Button
              variant={VARIANT.PRIMARY}
              fullWidth
              element="button"
              type="submit"
            >
              {button.text}
            </Button>
          </div>
        </div>
      </div>
      {isSubmitted && (
        <button
          className={style.successInformer}
          onClick={() => handleCloseInformer('successInformer')}
        >
          {successInformerText}
        </button>
      )}
      {submitError && (
        <button
          className={style.errorInformer}
          onClick={() => handleCloseInformer('errorInformer')}
        >
          {`${submitError.message}. Please, try later`}
        </button>
      )} */}
        {/* <ReCAPTCHA
            ref={reCaptchRef}
            sitekey={SITE_KEY}
            onChange={handleCaptureVerify}
            asyncScriptOnLoad={() => console.log('reCAPTCHA loaded')}
          // style={{ display: submissionCount === 0 ? 'none' : 'block' }}
          /> */}
      </>
    </form>
  );
};

Form.propTypes = {
  content: PropTypes.object,
};

export default Form;
